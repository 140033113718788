<template>
  <div class="brand-detail reset-margin">
    <navigationTop
      startTitle="平台-品牌"
      :visibleOption="['categoryCascader', 'selectDropdownFocus']"
      :defaultCategoryValue="[['zhinan'], ['zujian']]"
      :isRead="false"
      brandType="brand"
      @onNaviEvent="onNaviEvent"
      @setTitle="setTitle"
    />
    <charts v-model="chartData">
      <template v-slot:header>
        <echartCondition
          :resetTimes="resetTimes"
          @setCheck="setCheck"
          @setTop="setChart"
        ></echartCondition>
      </template>
      <template v-slot:summary>
        <dataSummary
          header="品牌销售金额"
          :toolTip="brandAmountTooltip"
          :text="toThousands(chartData.sales_amount_total)"
          type="amount"
        />
        <dataSummary
          header="品牌销售件数"
          :toolTip="brandNumTooltip"
          :text="toThousands(chartData.sales_num_total)"
          type="number"
          unit="件"
        />
      </template>
    </charts>

    <chartTable :tableData="tableData" :firstColumns="firstColumns">
      <template v-slot:search>
        <tableCondition
          :resetTimes="resetTimes"
          @setData="setTableData"
        ></tableCondition>
      </template>
      <template v-slot:screen>
        <openTable
          :tableData="tableData"
          :firstColumns="firstColumns"
          :resetTimes="resetTimes"
          :title="dialogTitle"
        ></openTable>
      </template>
    </chartTable>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'

import charts from '@/components/charts/charts'
import axios from '@/utils/request'
import chartTable from '@/components/chart-table/index'
import openTable from '@/components/chart-table/open-table.vue'
import navigationTop from '@/components/navigation-top'
import echartCondition from '@/components/charts/echart-condition'
import { chartsConditionFn } from '@/components/charts/charts-condition-fn'
import tableCondition from '@/components/chart-table/table-condition'
import dataSummary from '@/components/data-summary'
import { toThousands } from '@/utils/index'
import isInterface from '@/components/Interface-condition'
import debounce from 'lodash/debounce'
import {
  brandAmountTooltip,
  brandNumTooltip
} from '@/components/global-tooltip'

const FIRST_COLUMNS = [
  {
    prop: 'amount',
    label: '销售金额'
  },
  {
    label: '销售件数',
    prop: 'num'
  },
  {
    label: '成交价',
    prop: 'avg'
  }
]
export default defineComponent({
  name: 'ShopRank',
  components: {
    charts,
    chartTable,
    navigationTop,
    echartCondition,
    tableCondition,
    dataSummary,
    openTable
  },
  setup () {
    const chartData = reactive({})
    let setChartCondition = {}
    let setTableCondition = {}
    const chartCondition = reactive({ amount: true, number: true })
    let constData = {}
    const tableObj = reactive({})

    const getChartData = debounce(() => {
      if (!isInterface(setChartCondition)) return
      axios
        .get('/api/v1/brand_data/detail_statistics', {
          params: setChartCondition,
          loading: true
        })
        .then((res) => {
          if (res && res.error_code === 0) {
            constData = JSON.parse(JSON.stringify(res.data))
            Object.keys(res.data).forEach((d) => {
              chartData[d] = res.data[d]
            })
            chartsConditionFn(chartCondition, constData, chartData)
          }
        })
    }, 300)

    const tableData = reactive({})
    const getTableData = debounce(() => {
      if (!isInterface(setChartCondition)) return
      axios
        .get('/api/v1/brand_data/detail_data_list', {
          params: setTableCondition,
          loading: true
        })
        .then((res) => {
          if (res && res.error_code === 0) {
            Object.keys(res.data).forEach(key => {
              tableData[key] = res.data[key]
            })
          }
        })
    }, 300)

    watch(tableObj, () => {
      setTableCondition = { ...tableObj }
    })

    // 重置列表查看日期区间
    const resetTimes = reactive({})
    const onNaviEvent = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      setTableCondition = { ...setTableCondition, ...value }
      resetTimes.dateValue = value
      getChartData()
      getTableData()
    }

    const setChart = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      getChartData()
    }

    const setCheck = (value) => {
      Object.keys(value).forEach((l) => {
        chartCondition[l] = value[l]
      })
      chartsConditionFn(value, constData, chartData)
    }

    const setTableData = (value) => {
      setTableCondition = { ...setTableCondition, ...value }

      getTableData()
    }

    const dialogTitle = ref('')
    const setTitle = (title) => {
      dialogTitle.value = title.secondName
    }

    return {
      firstColumns: FIRST_COLUMNS,
      chartCondition,
      chartData,
      tableData,
      tableObj,
      resetTimes,
      setTableData,
      onNaviEvent,
      setCheck,
      toThousands,
      setChart,
      brandAmountTooltip,
      brandNumTooltip,
      setTitle,
      dialogTitle
    }
  }
})
</script>
<style lang="scss" scoped>
.brand-detail {
  .charts {
    margin-top: 96px;
  }
}
.reset-margin .charts {
  margin-top: 162px;
}
</style>
